import request from '@/utils/request'

const zone_propertyApi = {
  zone_property_list: '/zone_property/',
  cavancy_rate: '/zone_property_statistics/',
  zone_property_create: '/zone_property/',
  zone_property_update: '/zone_property/',
  zone_property_delete: '/zone_property/',
  zone_property_img_update: '/zone_property/img/',
  zone_property_img_delete: '/zone_property/img/',
  property_statistics_history: '/property_statistics_history/',
  export_property: '/export_property/'
}

/**
 * 列表
 */
export function cavancy_rate (parameter) {
  return request({
    url: zone_propertyApi.cavancy_rate,
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    params: parameter
  })
}
/**
 * 列表
 */
export function zone_property_list (parameter) {
  return request({
    url: zone_propertyApi.zone_property_list,
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    params: parameter
  })
}
/**
 * 添加
 */
export function zone_property_create (parameter) {
  return request({
    url: zone_propertyApi.zone_property_create,
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}
/**
 * 修改
 */
export function zone_property_update (parameter, zone_property_id) {
  return request({
    url: zone_propertyApi.zone_property_update + zone_property_id + '/',
    method: 'put',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}

/**
 * 修改
 * @param
 * {
 *  "change_to": 0
 * }
 */
export function zone_property_img_update (parameter) {
  return request({
    url: zone_propertyApi.zone_property_img_update,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}

/**
 * 删除
 */
export function zone_property_img_delete (zone_property_id) {
  return request({
    url: zone_propertyApi.zone_property_img_delete + zone_property_id + '/',
    method: 'delete',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}

export function zone_property_delete (zone_property_id) {
  return request({
    url: zone_propertyApi.zone_property_delete + zone_property_id + '/',
    method: 'delete',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}

/**
 * 资产历史
 */
export function property_statistics_history (parameter) {
  return request({
    url: zone_propertyApi.property_statistics_history,
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    params: parameter
  })
}

/**
 * 列表
 */
export function export_property (parameter) {
  return request({
    url: zone_propertyApi.export_property,
    method: 'get',
    headers: {
      'Content-Type': 'application/octet-stream'
    },
    responseType: 'blob',
    params: parameter
  })
}
