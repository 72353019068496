<template>
  <div>
    <div class="table-page-search-wrapper">
      <a-form layout="inline">
        <a-row :gutter="48">
          <a-col :md="4" :sm="24">
            <a-form-item label="名称">
              <a-input v-model="queryParam.name" placeholder="请输入" allowClear/>
            </a-form-item>
          </a-col>
          <a-col :md="4" :sm="24">
            <a-form-item label="位置">
              <a-select default-value="" style="width: 100%" v-model="queryParam.location" allowClear placeholder="请选择">
                <a-select-option v-for="item in this.$Dictionaries.location" :value="Object.keys(item)[0]" :key="Object.values(item)[0]"> {{ Object.values(item)[0] }} </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="4" :sm="24">
            <a-form-item label="类型">
              <a-select default-value="" style="width: 100%" v-model="queryParam.property_type" allowClear placeholder="请选择" >
                <a-select-option v-for="(val, key) in this.$Dictionaries.property_type" :value="key" :key="key">
                  {{ val }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="8" :sm="24">
            <a-button type="primary" @click="$refs.table.refresh(true)">查询</a-button>
          </a-col>
        </a-row>
      </a-form>
    </div>

    <div class="table-operator">
      <a-button type="primary" icon="plus" @click="handleAdd" v-action:add>新建</a-button>
      <a-button type="primary" icon="export" @click="handleExcel">导出</a-button>
    </div>

    <s-table
      ref="table"
      size="small"
      rowKey="id"
      :loading="loading"
      :columns="columns"
      :pageNum="pageNum"
      :data="loadData"
      :scroll="{ x:1000 }"
    >
      <span slot="serial" slot-scope="text, record, index">
        {{ index + 1 }}
      </span>
      <span slot="used_rate" slot-scope="text, record">
        <span :style="activation((record.used * 100 / record.total).toFixed(2))">{{ Math.abs(record.total) < 1 ? '-' : (record.used * 100 / record.total).toFixed(2)+' %' }}</span>
      </span>
      <div slot="count_render" slot-scope="text" style="text-align: right;">
        {{ text.toFixed(2) }}
      </div>
      <div slot="money_render" slot-scope="text" style="text-align: right;">
        {{ (text / 100).toFixed(2) | numberFormat }}
      </div>
      <span slot="imgs" slot-scope="image_urls">
        <a>
          <a-avatar
            v-if="image_urls.length > 0"
            slot="image_urls"
            :src="image_urls[0].img"
            shape="square"
            @click="showAllImages(image_urls)"
          />
        </a>
        <a v-if="image_urls.length > 1" @click="showAllImages(image_urls)">...</a>
      </span>
      <span slot="action" slot-scope="text, record">
        <template>
          <a @click="handleDetail(record)" v-action:query>资产明细</a>
          <a-divider type="vertical" />
          <a-popconfirm
            title="确定要删除么？"
            ok-text="确定"
            cancel-text="取消"
            @confirm="handleDelet(record)"
            v-action:delete
          >
            <a>删除</a>
          </a-popconfirm>
          <a-divider type="vertical" />
          <a-dropdown>
            <a class="ant-dropdown-link">
              更多 <a-icon type="down" />
            </a>
            <a-menu slot="overlay">
              <a-menu-item v-action:edit>
                <a @click="handleEdit(record)" >编辑</a>
              </a-menu-item>
              <a-menu-item v-action:edit>
                <a @click="editImages(record)">编辑图片</a>
              </a-menu-item>
              <a-menu-item >
                <a @click="corporationList(record)" >企业信息</a>
              </a-menu-item>

            </a-menu>
          </a-dropdown>
        </template>
      </span>
    </s-table>
    <a-drawer
      title="企业列表"
      placement="right"
      :closable="false"
      :visible="visibleDrawer"
      @close="onClose"
      width="500"
    >
      <a-table
        :columns="drawer_columns"
        :data-source="corporations"
        :pagination="false"
      >
        <span slot="serial" slot-scope="text, record, index">{{ index + 1 }}
        </span>
      </a-table>
    </a-drawer>
    <create-form
      v-if="visible"
      ref="createModal"
      :visible="visible"
      :loading="confirmLoading"
      :model="mdl"
      @cancel="handleAddCancel"
      @ok="handleOk"
    />
    <edit-images-form
      v-if="images_visible"
      ref="createImagesModal"
      :visible="images_visible"
      :loading="confirmImagesLoading"
      :model="mdl_imgs"
      @cancel="handleImagesCancel"
      @ok="handleImagesOk"
    />
    <preview
      ref="preview"
      :visible="previsible"
      :loading="preLoading"
      :content="content"
      @cancel="handlepreCancel"
      @ok="handlepreOk"
    />

    <a-modal v-model="previewvisible" title="预览" width="350px" :footer="null">
      <img alt="example" style="width: 300px; margin: 0 auto" :src="previewurl" />
    </a-modal>
  </div>
</template>

<script>
import moment from 'moment'
import { STable } from '@/components'
import {
  zone_property_list,
  zone_property_create,
  zone_property_img_update, zone_property_delete, export_property
} from '@/api/zone_property'
import { Dictionaries } from '@/common/AllConstant'
import CreateForm from '../modules/CreateForm'
import EditImagesForm from '../modules/EditImagesForm'
import Preview from '../modules/preview'

export default {
  name: 'TableList',
  components: {
    EditImagesForm,
    STable,
    CreateForm,
    Preview
  },
  data () {
    return {
      property_bill_visible: false,
      rent_bill_visible: false,
      previewvisible: false,
      previewurl: '',
      corporations: [],
      corporation_id: null,
      tip: '',
      corList: [],
      visibleDrawer: false,
      bookLoading: false,
      billLoading: false,
      billTime: {},
      content: '',
      pageNum: 1,
      previsible: false,
      loading: false,
      confirmLoading: false,
      confirmPropertyBillLoading: false,
      confirmRentBillLoading: false,
      confirmAttachmentLoading: false,
      preLoading: false,
      dictionaries: Dictionaries,
      visibleAttachment: false,
      attachmentListItemId: 0,
      // 创建窗口控制
      images_visible: false,
      confirmImagesLoading: false,
      visible: false,
      mdl: {},
      mdl_imgs: {},
      // 高级搜索 展开/关闭
      advanced: false,
      // 查询参数
      queryParam: {},
      drawer_columns: [
        {
          title: '序号',
          scopedSlots: { customRender: 'serial' },
          align: 'center',
          width: 80
        },
        {
          title: '名称',
          dataIndex: 'name',
          align: 'center',
          ellipsis: true
        }
      ],
      // 表头
      columns: [
        {
          title: '序号',
          scopedSlots: { customRender: 'serial' },
          fixed: 'left',
          width: 50
        },
        {
          title: '名称',
          dataIndex: 'name',
          fixed: 'left',
          width: 120,
          ellipsis: true
        },
        {
          title: '所属区域',
          dataIndex: 'area',
          width: 150,
          ellipsis: true
        },
        {
          title: '总面积',
          dataIndex: 'total',
          scopedSlots: { customRender: 'count_render' },
          align: 'right',
          width: 100
        },
        {
          title: '计租面积',
          dataIndex: 'count_total',
          align: 'right',
          scopedSlots: { customRender: 'count_render' },
          width: 100
        },
        {
          title: '已使用面积',
          dataIndex: 'used',
          align: 'right',
          scopedSlots: { customRender: 'count_render' },
          width: 100
        },
        {
          width: 100,
          title: '使用率',
          align: 'right',
          scopedSlots: { customRender: 'used_rate' }
        },
        {
          title: '可使用面积',
          dataIndex: 'available',
          width: 100,
          scopedSlots: { customRender: 'count_render' },
          align: 'right'
        },
        {
          title: '位置',
          dataIndex: 'location',
          customRender: (text) => this.dictionaries.location.find((item) => !!item[text])[text],
          ellipsis: true,
          align: 'center',
          width: 100
        },
        {
          title: '最大电容',
          dataIndex: 'max_electric',
          ellipsis: true,
          align: 'center',
          width: 100
        },
        {
          title: '资产归属',
          dataIndex: 'property_owner',
          customRender: (text) => this.$Dictionaries.property_owner[text] || '无',
          ellipsis: true,
          align: 'center',
          width: 100
        },
        {
          title: '类型',
          dataIndex: 'property_type',
          customRender: (text) => this.$Dictionaries.property_type[text] || '无',
          ellipsis: true,
          align: 'center',
          width: 100
        },
        {
          title: '物业费',
          dataIndex: 'fee',
          width: 140,
          scopedSlots: { customRender: 'money_render' },
          align: 'right'
        },
        {
          title: '租金',
          width: 140,
          align: 'right',
          dataIndex: 'standard_price',
          scopedSlots: { customRender: 'money_render' }
        },
        {
          title: '备注',
          dataIndex: 'remark',
          ellipsis: true,
          align: 'center',
          width: 200
        },
        {
          width: 100,
          title: '图片',
          dataIndex: 'imgs',
          scopedSlots: { customRender: 'imgs' }
        },
        {
          width: 200,
          title: '操作',
          dataIndex: 'action',
          fixed: 'right',
          scopedSlots: { customRender: 'action' }
        }
      ],
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        return zone_property_list(Object.assign(parameter, this.queryParam))
          .then(res => {
            return res.data
          })
      }
    }
  },
  created () {
    this.setCurrentPage()
  },
  methods: {
    setCurrentPage () {
      // const page = sessionStorage.getItem('CurrentPage')
      const page = this.$store.getters.pop
      if (page) {
        const current = page.split('#$$#')[0]
        const query = page.split('#$$#')[1]
        const path = page.split('#$$#')[2]
        sessionStorage.removeItem('CurrentPage')
        if (path === this.$route.path) {
          if (!isNaN(Number(current))) {
            this.pageNum = Number(current)
          }
          if (query) {
            this.queryParam = JSON.parse(query)
          }
        } else {
          this.queryParam = {}
          this.$store.dispatch('clear')
        }
      }
    },
    showDrawer () {
      this.visibleDrawer = true
    },
    onClose () {
      this.visibleDrawer = false
    },
    corporationList (record) {
      this.corporations = record.corporation_name.map(item => {
        return { name: item }
      })
      this.showDrawer()
    },
    getFileSuffix (filename) {
      return filename.substring(filename.lastIndexOf('.') + 1).toLowerCase()
    },
    getFileName (filename) {
      let name = filename.substring(filename.lastIndexOf('/') + 1)
      const suffix = filename.substring(filename.lastIndexOf('.')).toLowerCase()
      name = name.length - suffix.length > 10 ? name.substring(0, 5) + '...' + name.substring(8, 10) + suffix : name
      return name
    },
    getFileFullName (filename) {
      return filename.substring(filename.lastIndexOf('/') + 1)
    },
    openPreview (img_url) {
      const fileType = ['pdf', 'jpg', 'jpeg', 'png']
      const suffix = img_url.substring(img_url.lastIndexOf('.') + 1).toLowerCase()
      if (fileType.indexOf(suffix) > -1) {
        this.previewvisible = true
        this.previewurl = img_url
      } else {
        this.$message.error('附件错误，不支持预览')
      }
    },
    onChange (value) {
      console.log(value)
    },
    onSearch (searchText) {
    },
    onSelect (value) {
      this.queryParam.corporation_id = this.corList.filter((item) => (item.name === value) && item)[0].id
      console.log('onSelect', value, this.corList.filter((item) => (item.name === value) && item))
    },
    editImages (record) {
      this.mdl_imgs = record
      this.images_visible = true
    },
    handleAdd () {
      this.mdl = null
      this.visible = true
    },
    handleExcel () {
      export_property(this.queryParam).then((res) => {
        const link = document.createElement('a')
        const blob = new Blob([res], { type: 'application/octet-stream' })
        console.log(blob)
        link.style.display = 'none'
        link.href = URL.createObjectURL(blob)
        link.setAttribute('download', `资产.xlsx`)
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      })
    },
    handlepreCancel () {
      this.previsible = false
      console.log('222222')
    },
    handlepreOk () {
      this.previsible = false
      console.log('1111111')
    },
    showAllImages (urls) {
      this.previsible = true
      this.content = urls.map(url => {
        return url.img
      }).toString()
    },
    handleImagesCancel () {
      this.images_visible = false
      const form = this.$refs.createImagesModal.form
      form.resetFields() // 清理表单数据（可不做）
      this.$refs.table.refresh()
    },
    handleAddCancel () {
      this.visible = false
      const form = this.$refs.createModal.form
      form.resetFields() // 清理表单数据（可不做）
    },
    handleDetail (record) {
      // sessionStorage.setItem('CurrentPage', this.$refs.table.getCurrent() + '#$$#' + JSON.stringify(this.queryParam) + '#$$#' + this.$route.path)
      this.$store.dispatch('push', this.$refs.table.getCurrent() + '#$$#' + JSON.stringify(this.queryParam) + '#$$#' + this.$route.path)
      // query: { record: Base64.encode(JSON.stringify(record)) }
      this.$router.push({ path: '/zone_property_cell/index/' + record.id, query: { record: record.name } })
    },
    handleEdit (record) {
      // sessionStorage.setItem('CurrentPage', this.$refs.table.getCurrent() + '#$$#' + JSON.stringify(this.queryParam) + '#$$#' + this.$route.path)
      this.$store.dispatch('push', this.$refs.table.getCurrent() + '#$$#' + JSON.stringify(this.queryParam) + '#$$#' + this.$route.path)
      // this.$emit('onEdit', record)
      this.$router.push({ path: '/zone_property/index/' + record.id })
    },
    handleDelet (record) {
      zone_property_delete(record.id).then((res) => {
        console.log(res)
        console.log('删除成功------')

        this.$refs.table.refresh()
      })
    },
    handleImagesOk () {
      const form = this.$refs.createImagesModal.form
      this.confirmImagesLoading = true
      form.validateFields((errors, values) => {
        if (values.imgs === undefined || values.imgs.length === 0) {
          this.confirmImagesLoading = false
          this.images_visible = false
          // 重置表单数据
          form.resetFields()
          // 刷新表格
          this.$refs.table.refresh()
          return
        }
        if (!errors) {
          values.imgs = values.imgs.split(',')
          values.img = undefined
          values.zone_property_id = this.mdl_imgs.id
          zone_property_img_update(values).then(res => {
            this.images_visible = false
            // 重置表单数据
            form.resetFields()
            // 刷新表格
            this.$refs.table.refresh()
          }).finally(() => {
            this.confirmImagesLoading = false
          })
        } else {
          this.confirmImagesLoading = false
        }
      })
    },
    handleOk () {
      const form = this.$refs.createModal.form
      this.confirmLoading = true
      form.validateFields((errors, values) => {
        if (!errors) {
          console.log('values', values)
          // 新增
          values.fee = Math.round(values.fee * 10000)
          values.standard_price = Math.round(values.standard_price * 100)
          values.location = Number(values.location).valueOf()
          values.property_owner = parseInt(values.property_owner, 10)
          values.property_type = parseInt(values.property_type, 10)
          if (values.imgs !== undefined && values.imgs.length > 0) {
            values.imgs = values.imgs.split(',')
          }
          values.img = undefined
          zone_property_create(values).then(res => {
            this.visible = false
            // 重置表单数据
            form.resetFields()
            // 刷新表格
            this.$refs.table.refresh()
            console.log('创建成功------')
          }).finally(() => {
            this.confirmLoading = false
          })
        } else {
          this.confirmLoading = false
        }
      })
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },

    resetSearchForm () {
      this.queryParam = {
        date: moment(new Date())
      }
    }
  },
  computed: {
    activation () {
      return (used_rate) => {
        if (!Number.isFinite(Number(used_rate))) {
          return { 'color': 'red' }
        }
        if (used_rate > 85) {
          return { 'color': 'Green' }
        } else if (used_rate > 50) {
          return { 'color': 'Orange' }
        } else {
         return { 'color': 'red' }
        }
      }
    }
  }
}
</script>
