<template>
  <a-card :bordered="false">
    <div>
      <a-spin :spinning="loading">
        <a-form :form="form" v-bind="formLayout">
          <a-row :gutter="[16,16]">
            <a-col :span="12">
              <a-form-item label="Id" v-show="false">
                <a-textarea v-decorator="['id', {rules: [{required: false, message: '必填项，请填写信息'}]}]" />
              </a-form-item>
              <a-form-item label="名称">
                <a-input
                  v-decorator="['name', {rules: [{required: true, message: '必填项，请填写信息'}, {max:32, message: '输入超出长度限制'}]}]"
                  placeholder="请输入"
                />
              </a-form-item>
              <a-form-item label="所属区域">
                <a-input
                  v-decorator="['area', {rules: [{required: false, message: '必填项，请填写信息'}, {max:16, message: '输入超出长度限制'}]}]"
                  placeholder="请输入"
                />
              </a-form-item>
              <a-form-item label="位置">
                <a-select
                  v-decorator="[
                    'location',
                    {
                      rules: [
                        { required: true, message: '请选择' },
                      ]
                    },
                  ]"
                  placeholder="选择"
                >
                  <a-select-option v-for="item in this.$Dictionaries.location" :value="Object.keys(item)[0]" :key="Object.values(item)[0]"> {{ Object.values(item)[0] }} </a-select-option>
                </a-select>
              </a-form-item>
              <a-form-item label="最大电容">
                <a-input
                  v-decorator="['max_electric', {rules: [{required: true, message: '必填项，请填写信息'}]}]"
                  placeholder="请输入"
                />
              </a-form-item>
              <a-form-item label="资产归属">
                <a-select
                  v-decorator="[
                    'property_owner',
                    {
                      rules: [
                        { required: true, message: '请选择' },
                      ]
                    },
                  ]"
                  placeholder="选择"
                >
                  <a-select-option v-for="(val, key) in this.$Dictionaries.property_owner" :value="key" :key="key">
                    {{ val }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item label="资产类型">
                <a-select
                  v-decorator="[
                    'property_type',
                    {
                      rules: [
                        { required: true, message: '请选择' },
                      ]
                    },
                  ]"
                  placeholder="选择"
                >
                  <a-select-option v-for="(val, key) in this.$Dictionaries.property_type" :value="key" :key="key">
                    {{ val }}
                  </a-select-option>
                </a-select>
              </a-form-item>
              <a-form-item label="物业费">
                <a-input-number
                  :min="0"
                  v-decorator="['fee', {rules: [{required: true, message: '必填项，请填写信息'}]}]"
                />
                <span style="margin-left: 10px">(元/平米/天)</span>
              </a-form-item>
              <a-form-item label="标准租金">
                <a-input-number
                  :min="0"
                  v-decorator="['standard_price', {rules: [{required: true, message: '必填项，请填写信息'}]}]"
                />
                <span style="margin-left: 10px">(元/平米/天)</span>
              </a-form-item>
              <a-form-item label="备注">
                <a-textarea :autoSize="{minRows:2,maxRows:6}" v-decorator="['remark', {initialValue: '',rules: [{required: false, message: '必填项，请填写信息'}, {max:256, message: '输入超出长度限制'}]}]" />
              </a-form-item>
              <a-form-item label="上传" v-show="false">
                <a-textarea v-decorator="['imgs', {rules: [{required: false, message: '必填项，请填写信息'}]}]" />
              </a-form-item>
              <a-form-item label="图片上传" v-show="fileList.length !== 0">
                <div class="clearfix">
                  <a-upload
                    disabled
                    v-decorator="['img', { valuePropName: 'img'}]"
                    name="file"
                    accept="image/png, image/jpeg, image/jpg"
                    :action="actionUrl"
                    :data="new_multipart_params"
                    list-type="picture-card"
                    :file-list="fileList"
                    :before-upload="beforeUpload"
                    @preview="handlePreview"
                    @change="handleChange"
                  >
                    <div v-if="false">
                      <a-icon type="plus" />
                      <div class="ant-upload-text">
                        上传
                      </div>
                    </div>
                  </a-upload>
                  <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
                    <img alt="example" style="width: 100%" :src="previewImage" />
                  </a-modal>
                </div>
              </a-form-item>
            </a-col>
          </a-row>
          <a-row :gutter="[16,16]">
            <a-form-item>
              <a-col span="3" offset="19">
                <a-button type="primary" html-type="submit" @click="handleSubmit">提交</a-button>
              </a-col>
              <a-col span="2">
                <a-button @click="handleGoBack">返回</a-button>
              </a-col>
            </a-form-item>
          </a-row>
        </a-form>
      </a-spin>
    </div>
  </a-card>
</template>

<script>
// import moment from 'moment'
import pick from 'lodash.pick'
import { zone_property_img_delete, zone_property_update, zone_property_list } from '@/api/zone_property'
function getBase64 (file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  })
}
export default {
  name: 'TableEdit',
  props: {
    record: {
      type: [Object, String],
      default: ''
    }
  },
  data () {
    this.formLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 7 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 13 }
      }
    }
    return {
      loading: false,
      form: this.$form.createForm(this),
      id: 0,
      actionUrl: '',
      previewVisible: false,
      previewImage: '',
      new_multipart_params: {
        'key': '',
        'policy': '',
        'OSSAccessKeyId': '',
        'success_action_status': '200',
        'signature': ''
      },
      fileList: [],
      filePathList: [],
      filePathUrls: ''
    }
  },
  // beforeCreate () {
  //   this.form = this.$form.createForm(this)
  // },
  mounted () {
    this.$nextTick(() => {
      // this.loadEditInfo(this.record)
      zone_property_list({ id: this.$route.params.id })
        .then(res => {
          const list = res.data.entries
          if (list && list.length > 0) {
            this.loadEditInfo(list[0])
          }
        })
    })
  },
  methods: {
    handleGoBack () {
      // this.$emit('onGoBack')
      this.$router.go(-1)
    },
    beforeUpload (file) {
      const name = file.name
      const suffix = name.substring(name.lastIndexOf('.') + 1)
      this.new_multipart_params.key = file.uid + '.' + suffix
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
      if (!isJpgOrPng) {
        this.$message.error('你只能上传jpeg/png格式的图片')
      }
      const isLt5M = file.size / 1024 / 1024 < 5
      return isJpgOrPng && isLt5M
    },
    // 进口图片
    async handlePreview (file) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj)
      }
      console.log('我要预览', file.preview)
      this.previewImage = file.url || file.preview
      this.previewVisible = true
    },
    handleChange ({ fileList, file }) {
      this.fileList = fileList
      console.log('我是图片', file)
      console.log('我是图片------>>>', fileList)
      if (file.status === 'removed') {
        this.form.setFieldsValue({ imgs: '' })
        this.$message.error('请重新上传')
      }
      if (file.status === 'done') {
        this.filePathList.push(this.actionUrl + '/' + this.new_multipart_params.key)
        this.form.setFieldsValue({ imgs: this.filePathList.toString() })
      } else if (file.status === 'removed') {
        if (this.filePathUrls.search(file.url) >= 0) {
          zone_property_img_delete(file.id).then(res => {
            console.log(res)
            this.model.imgs.slice(this.filePathUrls.search(file.url), 1)
          })
        }
        this.filePathList = this.filePathList.filter(function (item) {
          return !(item.search(file.name) > 0)
        })
        this.form.setFieldsValue({ imgs: this.filePathList.toString() })
      }
    },
    handleSubmit () {
      const { form: { validateFields } } = this
      validateFields((err, values) => {
        if (!err) {
          this.loading = true
          values.fee = Math.round(values.fee * 10000)
          values.standard_price = Math.round(values.standard_price * 100)
          values.location = Number(values.location).valueOf()
          values.property_owner = parseInt(values.property_owner, 10)
          values.property_type = parseInt(values.property_type, 10)
          values.imgs = undefined
          values.img = undefined
          zone_property_update(values, this.id)
            .then((res) => {
              this.handleGoBack()
            }).finally(() => {
            this.loading = false
          })
        }
      })
    },
    handleCancel (file) {
      this.previewVisible = false
      console.log(file, '我是删除')
    },
    handleGetInfo () {

    },
    loadEditInfo (data) {
      const { form } = this
      console.log(`将加载 ${this} 信息到表单`)
      new Promise((resolve) => {
        setTimeout(resolve, 0)
      }).then(() => {
        data.location = data.location.toString()
        data.property_owner = data.property_owner.toString()
        data.property_type = data.property_type.toString()
        data.fee = (data.fee / 10000).toFixed(4)
        data.standard_price = (data.standard_price / 100).toFixed(2)
        if (data.imgs !== undefined && data.imgs.length > 0) {
          this.fileList = data.imgs.map((item, index) => {
            item.url = item.img
            item.uid = index
            item.status = 'done'
            item.name = item.img.substring(item.img.lastIndexOf('/') + 1)
            this.filePathUrls = this.filePathUrls + ',' + item.img
            return item
          })
          data.imgs = this.filePathUrls
        } else {
          data.imgs = ''
        }
        const formData = pick(data, ['id', 'name', 'area', 'location',
          'max_electric', 'property_owner', 'property_type', 'fee', 'standard_price', 'remark', 'imgs'])
        console.log('formData', formData)
        this.id = formData.id
        form.setFieldsValue(formData)
      })
    }
  }
}
</script>
