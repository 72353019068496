<template>
  <a-modal
    title="预览"
    :width="450"
    :visible="visible"
    :confirmLoading="loading"
    :footer="null"
    @cancel="() => { $emit('cancel') }"
  >
    <Carousel arrows dots-class="slick-dots slick-thumb">
      <a slot="customPaging" slot-scope="props">
        <a-avatar :src="getImgUrl(props.i)" />
      </a>
      <div v-for="item in content.split(',')" :key="item">
        <img style="width: 400px; margin: 0 auto" :src="item" />
      </div>
    </Carousel>
  </a-modal>
</template>
<script>
// 表单字段
import { Carousel } from 'ant-design-vue'
export default {
  components: {
    Carousel
  },
  props: {
    visible: {
      type: Boolean,
      required: false
    },
    loading: {
      type: Boolean,
      default: () => false
    },
    content: {
      type: String,
      default: ''
    }
  },
  data () {
    this.formLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 7 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 13 }
      }
    }
    return {}
  },
  created () {

  },
  methods: {
    handleOk () {
      this.$emit('ok')
    },
    getImgUrl (i) {
      return this.content.split(',')[i]
    }
  }
}
</script>
<style>
.ant-carousel >>> .slick-dots {
  height: auto;
}
.ant-carousel >>> .slick-slide img {
  border: 5px solid #fff;
  display: block;
  margin: auto;
  max-width: 80%;
}
.ant-carousel >>> .slick-thumb {
  bottom: -45px;
}
.ant-carousel >>> .slick-thumb li {
  width: 60px;
  height: 45px;
}
.ant-carousel >>> .slick-thumb li img {
  width: 100%;
  height: 100%;
  filter: grayscale(100%);
}
.ant-carousel >>> .slick-thumb li.slick-active img {
  filter: grayscale(0%);
}
</style>
